import React from 'react';
import {
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled,
} from '@ant-design/icons';
import { DEFAULT_DASH } from './constants';
import { CourseType } from '../../pages/currencyPairs/CourseType';
import { OperationChainType } from '../types';
import {
  BackendFilters,
  IOperationsChainConversion,
  TExchangePointStatus,
} from '../../interfaces';
import i18n from 'app/i18n';
import { t } from 'i18next';
import dayjs from 'dayjs';

import { useAnalyticsRequestsFiltersState } from '../../pages/analytics/state/useAnalyticsRequestsFiltersState';
import { useUserState } from 'shared/state/useUserState';
import {
  IOperationsFilters,
  TOperationsFiltersKey,
  TOperationsFiltersValue,
} from '../state/filtersState/useOperationsFiltersState';
import {
  ICFFilters,
  TCFFiltersKey,
  TCFFiltersValue,
} from '../state/filtersState/useCFFiltersState';
import { TContact } from '../state/contacts/common';

/**
 * Преобразовать ISO дату в формат ДД.ММ.ГГГГ ЧЧ:MM:CC
 * если передан второй параметр то переделывается в дату с учетом разницы во времени
 * @param ISODateAndTime
 * @param toUserzone
 */
//todo добавить timezone
export const ISOToDateAndTime = (
  ISODateAndTime: string,
  toUserzone = false
) => {
  const date = new Date(ISODateAndTime);
  const addZero = (num: number) => {
    return num < 10 ? '0' + num : num.toString();
  };
  const minutesOffset = date.getTimezoneOffset();
  if (toUserzone) {
    date.setMinutes(date.getMinutes() - minutesOffset);
  }

  const day = addZero(date.getDate());
  const month = addZero(date.getMonth() + 1);
  const year = date.getFullYear();
  const hours = addZero(date.getHours());
  const minutes = addZero(date.getMinutes());
  const seconds = addZero(date.getSeconds());

  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
};

/**
 * Форматирование из числа с ценой в строку с разбивкой по пробелам
 * @param cash
 */
export const cashFormatter = (cash: number | string | undefined) => {
  if (!cash) return '';
  const [integerPart, decimalPart] = cash.toString().split('.');
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ' '
  );

  return decimalPart
    ? `${formattedIntegerPart},${decimalPart.replace(/\s+/g, '')}`
    : formattedIntegerPart;
};

/**
 * Formats a number or string of cash into a formatted string.
 *
 * @param {number | string | undefined} cash - The cash value to be formatted.
 * @return {string} The formatted cash value as a string.
 */
export const calculatorFormatter = (cash: number | string | undefined) => {
  if (!cash) return '';
  const [integerPart, decimalPart] = cash.toString().split('.');
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '');

  return decimalPart
    ? `${formattedIntegerPart},${decimalPart.replace(/\./g, '')}`
    : formattedIntegerPart;
};

// удаляет пустой td из theader
export const removeTableRowWithoutContent = (): void => {
  const tableRows = document.querySelectorAll('tr');

  tableRows.forEach((row) => {
    const tableCells = row.querySelectorAll('.ant-table-cell');

    const hasEmptyTableCell = Array.from(tableCells).some((cell) => {
      return cell.innerHTML.trim() === '';
    });

    if (hasEmptyTableCell) {
      row.remove();
    }
  });
};

export const checkScenary = <T, U>(
  flag: boolean,
  primary?: T,
  secondary?: U
): T | U | undefined => (flag ? primary : secondary);

export const phoneFormat = (phone: string) => {
  let cleanedNumber = phone.replace(/\D/g, '');

  if (cleanedNumber.length !== 11) {
    return phone;
  }

  let formattedNumber =
    '+' +
    cleanedNumber.substring(0, 1) +
    '(' +
    cleanedNumber.substring(0, 3) +
    ') ' +
    cleanedNumber.substring(3, 6) +
    ' ' +
    cleanedNumber.substring(6, 8) +
    ' ' +
    cleanedNumber.substring(8);

  return formattedNumber;
};

// преобразует сумму к виду '2 222 222,22'
export const reformatingNumbers = (
  value: string,
  currency?: string,
  withSign: boolean = false
) => {
  if (['0', '0.00', '0.00000000'].includes(value)) {
    return '0' + (currency ? ' ' + currency.toUpperCase() : '');
  }
  if (!value) {
    return DEFAULT_DASH;
  } else {
    const numberParts = String(value)?.split('.');
    const integerPart = numberParts[0]
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      .replace('-', '- ');
    const sign = withSign ? getNumberSign(value) : '';
    return (
      sign +
      integerPart +
      (numberParts[1] ? ',' + numberParts[1] : '') +
      ' ' +
      (currency ? ' ' + currency.toUpperCase() : '')
    );
  }
};

export const getNumberSign = (course: string) => {
  if (/^\-/gm.test(course)) {
    return '';
  }
  return '+ ';
};

// мапит тип счёта
export const accountTypeMap: Record<
  'bank' | 'physical_safe' | 'electronic_wallet',
  string
> = {
  bank: 'Счёт в банке',
  physical_safe: 'Физическое хранилище',
  electronic_wallet: 'Электронный кошелёк',
};

export const pointStatusColorMap: Record<TExchangePointStatus, string> = {
  works: 'cyan',
  not_working: 'error',
};

export const pointStatusIconMap: Record<
  'works' | 'waiting' | 'closed' | 'canceled',
  React.ReactNode
> = {
  works: React.createElement(CheckCircleFilled, {
    style: { color: 'rgba(19, 194, 194, 1)' },
  }),
  canceled: React.createElement(CheckCircleFilled, {
    style: { color: 'rgba(19, 194, 194, 1)' },
  }),
  waiting: React.createElement(ClockCircleFilled, {
    style: { color: 'rgba(255, 192, 105, 1)' },
  }),
  closed: React.createElement(CloseCircleFilled, {
    style: { color: 'rgba(255, 120, 117, 1)' },
  }),
};

export const chainStatusIconMap: Record<
  'canceled' | 'waiting' | 'closed',
  React.ReactNode
> = {
  canceled: React.createElement(CheckCircleFilled, {
    style: { color: 'rgba(19, 194, 194, 1)' },
  }),
  waiting: React.createElement(ClockCircleFilled, {
    style: { color: 'rgba(255, 192, 105, 1)' },
  }),
  closed: React.createElement(CloseCircleFilled, {
    style: { color: 'rgba(255, 120, 117, 1)' },
  }),
};

export const operationChainTypeMap: Record<
  'bidding' | 'mmf' | 'exchange',
  string
> = {
  bidding: 'shared:тексты.Торги',
  mmf: 'shared:типы.Аренда',
  exchange: 'shared:тексты.Обмен',
};

export const operationMMFChainTypeMap: Record<
  'staff_wage' | 'loan' | 'rent' | 'balance_adjustment' | 'other' | string,
  string
> = {
  staff_wage: 'shared:статусы.Заработная плата персонала',
  rent: 'shared:типы.Аренда',
  loan: 'shared:статусы.Займ',
  balance_adjustment: 'shared:статусы.Корректировка остатков',
  other: 'shared:статусы.Прочее',
};

export const roleTypeMap: Record<'admin' | 'manager' | 'cashier', string> = {
  admin: 'shared:роли.Администратор',
  manager: 'shared:роли.Менеджер',
  cashier: 'shared:роли.Кассир',
};

export const roleMap: Record<number, string> = {
  1: 'admin',
  2: 'manager',
  3: 'cashier',
};

export const operationDirectionMap: Record<'input' | 'output', string> = {
  input: 'shared:тексты.Пополнение',
  output: 'shared:тексты.Списание',
};

export const mmfCodeTypeMap: Record<
  'rent' | 'loan' | 'staff_wage' | 'balance_adjustment' | 'other',
  string
> = {
  rent: 'shared:типы.Аренда',
  loan: 'shared:статусы.Займ',
  staff_wage: 'shared:статусы.Заработная плата персонала',
  balance_adjustment: 'shared:статусы.Корректировка остатков',
  other: 'shared:статусы.Прочее',
};

/**
 * многоточие после 38 символов
 * @param maxLength
 */

export const truncateText = (maxLength: string) => {
  if (maxLength.length <= 38) {
    return maxLength;
  }

  return maxLength.slice(0, 38) + '...';
};

/**
 * кастомный инфинити скролл с погрешностью в до пола всего ДД
 * @param event
 */
export const customInfinityScroll = (event: any) => {
  const target = event.target;
  return target.scrollTop + target.offsetHeight >= target.scrollHeight - 5;
};

/**
 * заменяет запятую на точку для отправки строки
 * @param str
 */
export const replaceCommaWithDotToString = (str: string): string => {
  const trimmedStr = str.replace(/^0+/, '').replace(',', '.');

  if (trimmedStr === '') {
    return '0';
  }

  const decimalIndex = trimmedStr.indexOf('.');
  if (decimalIndex === 0) {
    return '0' + trimmedStr;
  }

  return trimmedStr;
};

export const parseTextFromHtml = (html: string) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent || div.innerText || '';
};

/**
 * принимает объект, из которого вытягивает курс
 * @param courseData
 */
export const showCourse = (
  courseData: OperationChainType & { course?: CourseType }
): string => {
  return (
    `1 ${
      courseData?.course?.primary_currency_key.toUpperCase() || DEFAULT_DASH
    } = ${cashFormatter(
      courseData?.client_course
    )} ${courseData?.course?.secondary_currency_key.toUpperCase()}` ||
    DEFAULT_DASH
  );
};
/**
 * Get a string from name and lastname if they !== null
 * @param name
 * @param lastname
 */
export const getNameAndLastname = (
  name: string | null,
  lastname: string | null
) => {
  if (name !== null && lastname !== null) {
    return `${lastname} ${name[0].toUpperCase()}.`;
  } else if (lastname === null && name === null) {
    return DEFAULT_DASH;
  } else {
    return lastname ? lastname : name;
  }
};

/**
 * Do from array with conversions a chained string of conversions
 * @param data
 */
export const makeCoversionsChain = (data: IOperationsChainConversion[]) => {
  const currencies: string[] = [];
  if (data?.length) {
    data.forEach((item, index) => {
      if (index === 0) {
        currencies.push(item.secondary_currency_key);
        currencies.push(item.primary_currency_key);
        return;
      }
      currencies.push(item.primary_currency_key);
    });
    return currencies.join('/');
  }
};

export function changedFields<T extends object>(obj1: T, obj2: T): any {
  const result: any = {};

  for (const key in obj1) {
    if (key in obj1 && key in obj2) {
      if (obj1[key as keyof T] !== obj2[key as keyof T]) {
        result[key as keyof T] = obj1[key as keyof T];
      }
    }
  }
  return result;
}

export const makeCourseFilters = (company_id: number, currencies: string[]) => {
  if (currencies.length <= 1) {
    return [];
  }
  const filters: BackendFilters[] = [];
  const nestedCurrencies: BackendFilters[] = [];
  filters.push({
    field: 'company_id',
    operator: '=',
    value: company_id,
  });
  currencies.forEach((currency, index) => {
    if (index !== currencies.length - 1) {
      nestedCurrencies.push({
        type: 'or',
        nested: [
          {
            field: 'primary_currency_key',
            operator: '=',
            value: currency,
          },
          {
            field: 'secondary_currency_key',
            operator: '=',
            value: currencies[index + 1],
          },
        ],
      });
    }
  });
  filters.push({
    type: 'and',
    nested: nestedCurrencies,
  });

  return filters;
};

/**
 * This is function only for chain filter, it converts front object of filters to laravel filter object
 * Это функция только для фильтра цепочек, она конвертирует фронт объект фильтров в laravel фильтр объект
 * @param filters
 */

export const CFfiltersToBackend = (filters: ICFFilters) => {
  const backendFilters: any[] = [];
  Object.entries(filters).forEach(
    ([key, value]: (TCFFiltersKey | TCFFiltersValue)[]) => {
      switch (key) {
        case 'status':
          backendFilters.push({
            field: 'status',
            operator: 'in',
            value: filters[key]?.map((item) => item.value),
          });
          break;
        case 'exchange_point_id':
          backendFilters.push({
            field: 'exchange_point_id',
            operator: 'in',
            value: filters[key]?.map((item) => item.value),
          });
          break;
        case 'type':
          if (!!filters[key]?.value) {
            backendFilters.push({
              field: 'type',
              operator: '=',
              value: filters[key]?.value,
            });
          }
          break;
        case 'account_id':
          backendFilters.push({
            field: 'account.id',
            operator: '=',
            value: filters[key]?.map((item) => item.value),
          });
          break;
        case 'initiator_user_account_uid':
          backendFilters.push({
            field: 'initiator.user_account_id',
            operator: 'in',
            value: filters[key]?.map((item) => item.value),
          });
          break;
        case 'client_id':
          backendFilters.push({
            field: 'operationsChain.client_id',
            operator: 'in',
            value: filters[key]?.map((item) => item.value),
          });
          break;
        case 'currency_key':
          backendFilters.push({
            field: 'currency_key',
            operator: 'in',
            value: filters[key]?.map((item) => item.value),
          });
          break;
        case 'cf_created_at':
          filters[key]![0] &&
            backendFilters.push({
              field: 'created_at',
              operator: '>=',
              value: filters[key]![0],
            });
          filters[key]![1] &&
            backendFilters.push({
              field: 'created_at',
              operator: '<=',
              value: filters[key]![1],
            });
          break;
        case 'direction':
          const nested: {
            field: string;
            operator: string;
            value: string | number | undefined;
          }[] = [];
          filters[key]?.forEach((item) => {
            if (!item?.value) return;
            nested.push({
              field: 'direction',
              operator: '=',
              value: item.value,
            });
          });
          backendFilters.push({
            nested: nested.map((item, index) => {
              if (!index) return item;
              return { type: 'or', ...item };
            }),
          });
          break;
        default:
          break;
      }
    }
  );
  return backendFilters.map((item, index) => {
    if (!index) return item;
    // return { type: 'and', ...item };
    return { ...item };
  });
};

export const operationsFiltersToBackend = (filters: IOperationsFilters) => {
  const backendFilters: any[] = [];
  Object.entries(filters).forEach(
    ([key, value]: (TOperationsFiltersKey | TOperationsFiltersValue)[]) => {
      switch (key) {
        case 'status':
          backendFilters.push({
            field: 'status',
            operator: 'in',
            value: filters[key]?.map((item) => item.value),
          });
          break;
        case 'first_exchange_point_id':
          backendFilters.push({
            field: 'firstExchangePoint.id',
            operator: 'in',
            value: filters[key]?.map((item) => item.value),
          });
          break;
        case 'initiator_user_account_uid':
          backendFilters.push({
            field: 'initiator.user_account_id',
            operator: 'in',
            value: filters[key]?.map((item) => item.value),
          });
          break;
        case 'client_id':
          backendFilters.push({
            field: 'client.id',
            operator: 'in',
            value: filters[key]?.map((item) => item.value),
          });
          break;
        case 'rate_benefit':
          backendFilters.push({
            field: 'rate_benefit',
            operator: '<=',
            value: 0,
          });
          break;
        case 'operation_created_at':
          filters[key]![0] &&
            backendFilters.push({
              field: 'created_at',
              operator: '>=',
              value: filters[key]![0],
            });
          filters[key]![1] &&
            backendFilters.push({
              field: 'created_at',
              operator: '<=',
              value: filters[key]![1],
            });
          break;
        case 'operations_chain_type_id':
          const nested: {
            field: string;
            operator: string;
            value: string | number | undefined;
          }[] = [];
          filters[key]?.forEach((item) => {
            if (String(item.value)?.includes('operationType')) {
              nested.push({
                field: 'operationsChainType.id',
                operator: '=',
                value: +String(item.value).split(':')[1],
              });
            } else {
              nested.push({
                field: 'mmfCode.id',
                operator: '=',
                value: +String(item.value).split(':')[1],
              });
            }
          });
          backendFilters.push({
            nested: nested.map((item, index) => {
              if (!index) return item;
              return { type: 'or', ...item };
            }),
          });
          break;
        default:
          break;
      }
    }
  );
  return backendFilters.map((item, index) => {
    if (!index) return item;
    return { type: 'and', ...item };
  });
};

export const percentFormatter = (value: any) => {
  if (!value) {
    return '0 %';
  }
  return `${value} %`;
};
export const percentParser = (value: any) => {
  return value.replace(' %', '');
};

export const isContainMinus = (value: any) => {
  if (!value) {
    return;
  }
  return value.includes('-');
};

export const compareArrays = (a: any[], b: any[]) => {
  const aArr = a.sort();
  const bArr = b.sort();
  return (
    aArr.length === bArr.length &&
    aArr.every((value, index) => value === bArr[index])
  );
};

export const isPartObjectsEqual = (partObj: any, fullObj: any) => {
  return !Object.keys(partObj).some((key) => partObj[key] !== fullObj[key]);
};

/**
 * Function to find ready translated value or return value itself
 * @param translateQuery
 * @param name
 */
export const findTranslate = (translateQuery: string, name: string) => {
  if (!String(t(`${translateQuery}.${name}`)).includes('physicalExchanger')) {
    return String(t(`${translateQuery}.${name}`));
  }
  return name;
};
const checkOperationsChainType = (type: string) => {
  if (type === 'exchange') {
    return i18n.t('shared:тексты.Обмен');
  }
  if (type === 'bidding') {
    return i18n.t('shared:тексты.Торги');
  }
  if (type === 'mmf') {
    return i18n.t('shared:тексты.Корпоративное ДДС');
  }
};

export function createOptions<T>(data: any, stateName: string) {
  return data.map((elem: any) => {
    if (stateName === 'useExchangePointState') {
      return {
        id: elem?.id,
        value: elem?.name,
        isSelected: false,
        key: elem?.id,
      };
    }
    if (stateName === 'useCurrencyState') {
      return {
        id: elem?.id,
        value: elem?.shortname,
        isSelected: false,
        key: elem?.id,
      };
    }
    if (stateName === 'useExchangePointStateForAccounts') {
      return {
        key: elem?.id.toString(),
        value: elem?.id.toString(),
        label: elem?.name,
      };
    }
    if (stateName === 'useAccountStateForExchangePoints') {
      return {
        key: elem?.id.toString(),
        value: elem?.id.toString(),
        label: elem?.shortname,
      };
    }
    if (stateName === 'useAccountState') {
      const data = {
        id: elem?.id,
        value: '',
        isSelected: false,
        key: elem?.id,
      };
      if (!elem?.name && !elem?.shortname && !elem?.requisites) {
        data.value = `Noname - ${elem?.id}`;
      } else if (!!elem?.shortname) {
        data.value = elem?.shortname;
      } else if (!!elem?.name) {
        data.value = elem?.name;
      } else if (!!elem?.requisites) {
        data.value = elem?.requisites;
      }
      return data;
    } else if (stateName === 'useCurrencyState') {
      return {
        id: elem?.id,
        value: elem?.shortname,
        isSelected: false,
        key: elem?.id,
      };
    } else if (stateName === 'useOperationsChainTypeState') {
      return {
        id: elem.id,
        value: checkOperationsChainType(elem.name),
        type: elem.name,
        isSelected: false,
        key: elem?.id,
      };
    } else if (stateName === 'useMmfsState') {
      return {
        id: elem.id,
        value: elem.name,
        type: elem.name,
        isSelected: false,
        key: elem?.id,
      };
    } else if (stateName === 'useUserAccountsState') {
      const data = {
        id: elem?.id,
        value: '',
        uid: elem.uid,
        isSelected: false,
        key: elem?.id,
      };

      if (!!elem.username) {
        data.value = elem.username;
      } else if (!elem.firstname && !elem.lastname) {
        data.value = elem.username;
      } else if (!elem.username) {
        data.value = elem.phone_number;
      } else if (!!elem.firstname && !!elem.lastname) {
        data.value = `${elem.firstname} ${elem.lastname}`;
      } else {
        data.value = `Noname - ${elem.id}`;
      }

      return data;
    } else if (stateName === 'useClientsState') {
      const data = {
        id: elem?.id,
        value: '',
        isSelected: false,
        key: elem.id,
      };
      if (!elem.name && !elem.phone_number) {
        data.value = `Noname - ${elem.id}`;
      } else if (!!elem.name) {
        data.value = `${elem.name}`;
      } else if (!elem.name && !!elem.phone_number) {
        data.value = elem.phone_number;
      } else if (!elem.name && !elem.phone_number && !!elem.messenger) {
        data.value = elem.messenger;
      }

      return data;
    }
  });
}

export function getIdsArrFromArr(arr: any[]) {
  return arr.map((item) => item.id);
}

export function getCurrKeyFromArr(arr: any[]) {
  return arr.map((item) => item.key);
}

export function getUidFromArr(arr: any[]) {
  return arr.map((item) => item.uid);
}

export function getShortnameFromArr(arr: any[]) {
  return arr.map((item) => item.value);
}

export function getFiltersForAnalyticsRequests({ requestName }: any) {
  const filtersArr: any[] = [];
  const filtersState = useAnalyticsRequestsFiltersState.getState().filters;
  const date1 = filtersState.dateArr[0];
  const date2 = filtersState.dateArr[1];
  const accountsIdArr = getIdsArrFromArr(
    filtersState.accounts.filter((item) => item.isSelected)
  );
  const accountsUidArr = getUidFromArr(
    filtersState.employees.filter((item) => item.isSelected)
  );
  const exchangePointIdArr = getIdsArrFromArr(
    filtersState.exchangePoints.filter((item) => item.isSelected)
  );
  const currencyShortnameArr = getShortnameFromArr(
    filtersState.currencies.filter((item) => item.isSelected)
  );
  const operationTypesArr = getIdsArrFromArr(
    filtersState.operationTypes.filter((item) => item.isSelected)
  );
  const clientsIdArr = getIdsArrFromArr(
    filtersState.clients.filter((item) => item.isSelected)
  );
  const mmfsCodeIdArr = getIdsArrFromArr(
    filtersState.corpMmfs.filter((item) => item.isSelected)
  );
  const accountTypesArr = getIdsArrFromArr(
    filtersState.accountTypes.filter((item) => item.isSelected)
  );
  const user = useUserState.getState().userApi;

  const addDateFilter = () => {
    // Закомментил в рамках PHEX-4122, нужное ограничение реализовано в DateRange
    // if (!!date1 && !!date2 && user?.role?.name !== 'admin') {
    //   return;
    // } else
    if (!!date1 && !!date2) {
      filtersArr.push({
        field: 'lastClosedHistory.created_at',
        operator: '>=',
        value: dayjs(date1).format('YYYY-MM-DD 00:00:00'),
      });
      filtersArr.push({
        field: 'lastClosedHistory.created_at',
        operator: '<=',
        value: dayjs(date2).format('YYYY-MM-DD 23:59:59'),
      });
    } else if (!!date1) {
      filtersArr.push({
        field: 'lastClosedHistory.created_at',
        operator: '=',
        value: dayjs(date1).format('YYYY-MM-DD 00:00:00'),
      });
    }
  };

  const addDateFilterDebts = () => {
    if (!!date1 && !!date2) {
      filtersArr.push({
        field: 'created_at',
        operator: '>=',
        value: dayjs(date1).format('YYYY-MM-DD 00:00:00'),
      });
      filtersArr.push({
        field: 'created_at',
        operator: '<=',
        value: dayjs(date2).format('YYYY-MM-DD 23:59:59'),
      });
    } else if (!!date1) {
      filtersArr.push({
        field: 'created_at',
        operator: '=',
        value: dayjs(date1).format('YYYY-MM-DD 00:00:00'),
      });
    }
  };

  const addClientIdFilter = (isOperationChain: boolean) => {
    if (clientsIdArr.length > 1) {
      filtersArr.push({
        field: isOperationChain ? 'operationsChain.client_id' : 'client_id',
        operator: 'in',
        value: clientsIdArr,
      });
    } else if (clientsIdArr.length === 1) {
      filtersArr.push({
        field: isOperationChain ? 'operationsChain.client_id' : 'client_id',
        operator: '=',
        value: clientsIdArr[0],
      });
    }
  };

  const addAccountIdFilter = () => {
    if (accountsIdArr.length > 1) {
      filtersArr.push({
        field: 'account_id',
        operator: 'in',
        value: accountsIdArr,
      });
    } else if (accountsIdArr.length === 1) {
      filtersArr.push({
        field: 'account_id',
        operator: '=',
        value: accountsIdArr[0],
      });
    }
  };

  const addIdFilter = () => {
    if (accountsIdArr.length > 1) {
      filtersArr.push({
        field: 'id',
        operator: 'in',
        value: accountsIdArr,
      });
    } else if (accountsIdArr.length === 1) {
      filtersArr.push({
        field: 'id',
        operator: '=',
        value: accountsIdArr[0],
      });
    }
  };

  const addCurrencyKeyFilter = () => {
    if (currencyShortnameArr.length > 1) {
      filtersArr.push({
        field: 'currency_key',
        operator: 'in',
        value: currencyShortnameArr,
      });
    } else if (currencyShortnameArr.length === 1) {
      filtersArr.push({
        field: 'currency_key',
        operator: '=',
        value: currencyShortnameArr[0],
      });
    }
  };

  const addExchangePointIdFilter = () => {
    if (exchangePointIdArr.length > 1) {
      filtersArr.push({
        field: 'exchange_point_id',
        operator: 'in',
        value: exchangePointIdArr,
      });
    } else if (exchangePointIdArr.length === 1) {
      filtersArr.push({
        field: 'exchange_point_id',
        operator: '=',
        value: exchangePointIdArr[0],
      });
    }
  };

  const addFirstExchangePointDotIdFilter = () => {
    if (exchangePointIdArr.length > 1) {
      filtersArr.push({
        field: 'firstExchangePoint.id',
        operator: 'in',
        value: exchangePointIdArr,
      });
    } else if (exchangePointIdArr.length === 1) {
      filtersArr.push({
        field: 'firstExchangePoint.id',
        operator: '=',
        value: exchangePointIdArr[0],
      });
    }
  };

  const addExchangePointDotIdFilter = () => {
    if (exchangePointIdArr.length > 1) {
      filtersArr.push({
        field: 'exchangePoints.id',
        operator: 'in',
        value: exchangePointIdArr,
      });
    } else if (exchangePointIdArr.length === 1) {
      filtersArr.push({
        field: 'exchangePoints.id',
        operator: '=',
        value: exchangePointIdArr[0],
      });
    }
  };

  const addExchangePointDebtsFilter = () => {
    if (exchangePointIdArr.length > 1) {
      filtersArr.push({
        field: 'userAccount.exchangePoints.id',
        operator: 'in',
        value: exchangePointIdArr,
      });
    } else if (exchangePointIdArr.length === 1) {
      filtersArr.push({
        field: 'userAccount.exchangePoints.id',
        operator: '=',
        value: exchangePointIdArr[0],
      });
    }
  };

  const addUserAccountIdDebtsFilter = () => {
    if (accountsUidArr.length > 1) {
      filtersArr.push({
        field: 'user_account_id',
        operator: 'in',
        value: accountsUidArr,
      });
    } else if (accountsUidArr.length === 1) {
      filtersArr.push({
        field: 'user_account_id',
        operator: '=',
        value: accountsUidArr[0],
      });
    }
  };

  const addOperationsChainTypeDotIdFilter = () => {
    if (operationTypesArr.length > 1) {
      filtersArr.push({
        field: 'operationsChain.operations_chain_type_id',
        operator: 'in',
        value: operationTypesArr,
      });
    } else if (operationTypesArr.length === 1) {
      filtersArr.push({
        field: 'operationsChain.operations_chain_type_id',
        operator: '=',
        value: operationTypesArr[0],
      });
    }
  };

  const addOperationsChainTypeIdFilter = () => {
    if (operationTypesArr.length > 1) {
      filtersArr.push({
        field: 'operations_chain_type_id',
        operator: 'in',
        value: operationTypesArr,
      });
    } else if (operationTypesArr.length === 1) {
      filtersArr.push({
        field: 'operations_chain_type_id',
        operator: '=',
        value: operationTypesArr[0],
      });
    }
  };

  const addMMFCodeIdFilter = () => {
    if (mmfsCodeIdArr.length > 1) {
      filtersArr.push({
        field: 'mmf_code_id',
        operator: 'in',
        value: mmfsCodeIdArr,
      });
    } else if (mmfsCodeIdArr.length === 1) {
      filtersArr.push({
        field: 'mmf_code_id',
        operator: '=',
        value: mmfsCodeIdArr[0],
      });
    }
  };

  const addOperationsChainMMFCodeIdFilter = () => {
    if (mmfsCodeIdArr.length > 1) {
      filtersArr.push({
        field: 'operationsChain.mmf_code_id',
        operator: 'in',
        value: mmfsCodeIdArr,
      });
    } else if (mmfsCodeIdArr.length === 1) {
      filtersArr.push({
        field: 'operationsChain.mmf_code_id',
        operator: '=',
        value: mmfsCodeIdArr[0],
      });
    }
  };

  const addInitiatorUserAccountIdFilter = () => {
    if (accountsUidArr.length > 1) {
      filtersArr.push({
        field: 'initiator.user_account_id',
        operator: 'in',
        value: accountsUidArr,
      });
    } else if (accountsUidArr.length === 1) {
      filtersArr.push({
        field: 'initiator.user_account_id',
        operator: '=',
        value: accountsUidArr[0],
      });
    }
  };
  const addIAccountTypesIdFilter = (isDepositOrWithdrawal: boolean) => {
    if (accountTypesArr.length > 1) {
      filtersArr.push({
        field: isDepositOrWithdrawal ? 'account.type' : 'type',
        operator: 'in',
        value: accountTypesArr,
      });
    } else if (accountTypesArr.length === 1) {
      filtersArr.push({
        field: isDepositOrWithdrawal ? 'account.type' : 'type',
        operator: '=',
        value: accountTypesArr[0],
      });
    }
  };

  if (
    ['searchAnalyticArrivals', 'searchAnalyticExpenditures'].includes(
      requestName
    )
  ) {
    addDateFilter();
    addAccountIdFilter();
    addCurrencyKeyFilter();
    addOperationsChainMMFCodeIdFilter();
    addExchangePointIdFilter();
    addClientIdFilter(true);
    addOperationsChainTypeDotIdFilter();
    addInitiatorUserAccountIdFilter();
    addIAccountTypesIdFilter(true);
  }

  if (requestName === 'searchAnalyticDebts') {
    addDateFilterDebts();
    addCurrencyKeyFilter();
    addExchangePointDebtsFilter();
    addUserAccountIdDebtsFilter();
  }

  if (requestName === 'searchAnalyticRateBenefit') {
    addDateFilter(); //
    addClientIdFilter(false); //
    addMMFCodeIdFilter(); //
    addInitiatorUserAccountIdFilter();
    addOperationsChainTypeIdFilter();
    addFirstExchangePointDotIdFilter(); //
  }

  if (requestName === 'searchAnalyticAccountsBalance') {
    addIdFilter();
    addExchangePointDotIdFilter();
    addIAccountTypesIdFilter(false);
  }

  return filtersArr;
}

export const getDifferenceContacts = (
  initContacts: TContact[],
  changedContacts: TContact[]
) => {
  const deletedContactIds: number[] = [];
  const addedContactValues: string[] = [];

  initContacts.forEach(({ id }) => {
    if (!changedContacts.some((changedContact) => id === changedContact.id)) {
      deletedContactIds.push(id);
    }
  });

  changedContacts.forEach(({ id, value }) => {
    if (!initContacts.some((changedContact) => id === changedContact.id)) {
      addedContactValues.push(value);
    }
  });

  return { deletedContactIds, addedContactValues };
};
